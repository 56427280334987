import React from "react";
import { Message } from "react-chat-ui";
import { Button, Stack } from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
  NEW_CHATBOT_API_URL,
} from "../../../shared/constants/constants";
import {
  readChatJson,
  readPromptJson,
  saveChatJson,
  parseMarkdownTable,
  getChattingNameById,
  updateBackup,
  BackupObj,
  readBackupChatHistory,
  backupChatHistory,
  parseMarkdownText,
  getHFLLamaResponse,
  getLLamaFireworksAITextResponse,
} from "../../../shared/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatComponentState,
  TChatbotResponseData,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";
import ChatList from "../../../components/common/chats/ChatList";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";

const CHAT_TYPE = 0;

async function GetAIAnswer(
  history: THistoryTurnOne[],
  file_name_read_prompt: string
): Promise<TChatbotResponseData> {
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const { temperature, max_new_tokens } = promptData;
  const rsp = await getLLamaFireworksAITextResponse({
    max_tokens: max_new_tokens,
    history,
    temperature: temperature,
  });
  const { output } = rsp;
  if (!output) {
    throw new Error("AI ouptut is empty");
  }
  return {
    output: output,
    prompt: history
      .map((obj) => `${obj.id === 0 ? "User" : "AI"}: ${obj.message}`)
      .join("\n"),
    generate_ans_time: 0,
    generate_prompt_time: 0,
    speed: 0,
  };
}

type LLama32ChatComponentState = ChatComponentState & {
  file_name_backup_history: string;
  isBackedAlready: boolean;
  buttonArr: any[];
  imageUrl: any;
} & {
  page_index: number;
};

class LLama32ChatComponent extends React.Component<
  {},
  LLama32ChatComponentState
> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.testLLama32Chat,
      file_name_prompt: JSON_FILE_NAMES.testLLama32Prompt,
      file_name_backup_history: JSON_FILE_NAMES.testLLama32BackupHistory,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      isBackedAlready: false,
      buttonArr: [],
      input_message: "",
      page_index: 1,
      imageUrl: null,
    };
  }

  async componentDidMount() {
    const { history, prompt, additional_data } = await readChatJson(
      this.state.file_name_chat
    );
    if (additional_data) {
      this.setState({ imageUrl: additional_data });
    }
    this.populateChatWindow(history, prompt);
    this.setState({
      buttonArr: await this.getBackupButtonArr(),
    });
  }

  handleFileUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      this.setState({ imageUrl: reader.result });
      saveChatJson(
        this.state.history,
        this.state.prompt,
        this.state.file_name_chat,
        reader.result
      );
    };
    reader.readAsDataURL(file);
  }

  handleFileDelete(event: any) {
    this.setState({ imageUrl: null });
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message.trim();
    if (!text) return;
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    // const image = this.state.imageUrl
    //   ? await fetch(this.state.imageUrl)
    //       .then((res) => res.blob())
    //       .then((blob) => {
    //         const file = new File([blob], "image.png", { type: "image/png" });
    //         return file;
    //       })
    //   : null;
    const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
      await GetAIAnswer(this.state.history, this.state.file_name_prompt);
    this.pushEllaMessage(output, prompt);
    this.setState({
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push({ ...ellaMessage, messageRawText: message });
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ prompt, isBackedAlready: false });
    saveChatJson(
      this.state.history,
      prompt,
      this.state.file_name_chat,
      this.state.imageUrl
    );
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat,
      this.state.imageUrl
    );
  }

  async onBackupDelete(e: any, timestamp: number) {
    const backupArr = await this.getBackupArr();
    const newArr: BackupObj[] = [];
    for (const obj of backupArr) {
      if (obj.timestamp !== timestamp) {
        newArr.push(obj);
      }
    }
    await updateBackup(newArr, this.state.file_name_backup_history);
    this.setState({ buttonArr: await this.getBackupButtonArr() });
  }

  onApplyBackupToChatWindow(
    e: any,
    history: THistoryTurnOne[],
    prompt: string
  ) {
    e.preventDefault();
    this.populateChatWindow(history, prompt);
  }

  async getBackupButtonArr(): Promise<any[]> {
    const backupArr = await this.getBackupArr();
    backupArr.sort((a, b) => b.timestamp - a.timestamp);
    const buttonArr: any[] = [];
    backupArr.forEach((backupObj) => {
      const { timestamp, history, prompt } = backupObj;
      buttonArr.push(
        <div>
          <Button
            onClick={(e) => this.onApplyBackupToChatWindow(e, history, prompt)}
            style={{ margin: 10, width: 400 }}
            type="submit"
            variant="outlined"
            color="success"
          >
            {new Date(timestamp).toISOString()}
          </Button>
          <Button
            onClick={(e) => this.onBackupDelete(e, timestamp)}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      );
    });
    return buttonArr;
  }

  async getBackupArr(): Promise<BackupObj[]> {
    const curBackupArr: BackupObj[] = await readBackupChatHistory(
      this.state.file_name_backup_history
    );
    return curBackupArr;
  }

  async onBackupCurrentChat(e: any) {
    e.preventDefault();
    const backupArr: BackupObj[] = await this.getBackupArr();
    backupChatHistory(
      this.state.history,
      this.state.prompt,
      this.state.file_name_backup_history,
      backupArr
    );
    this.setState({ isBackedAlready: true });
  }

  // page control
  onClickTab(index: number) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
            }}
          >
            Text LLM Chatting
          </h3>
        </div>
        <div className="profile-details-tab">
          <div className="advance-tab-button mb--30">
            <ul
              className="nav nav-tabs tab-button-style-2 justify-content-start"
              id="settinsTab-4"
              role="tablist"
            >
              <li role="presentation">
                <a
                  href="#"
                  className={`tab-button ${
                    this.state.page_index === 1 && "active"
                  }`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-selected={this.state.page_index === 1}
                  onClick={() => this.onClickTab(1)}
                >
                  <span className="title">Generate</span>
                </a>
              </li>
              <li role="presentation">
                <a
                  href="#"
                  className={`tab-button ${
                    this.state.page_index === 2 && "active"
                  }`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-selected={this.state.page_index === 2}
                  onClick={() => this.onClickTab(2)}
                >
                  <span className="title">Prompt looks like this</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={"Ella"}
              userName={"You"}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ input_message: e.target.value });
              }}
              textareaValue={this.state.input_message}
              onBackupCurrentChat={(e) => this.onBackupCurrentChat(e)}
              onClearHistory={(e) => this.onClearHistory(e)}
            />
          </div>

          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div className="container" style={{ marginTop: 0 }}>
              {/* <Stack alignItems="center" spacing={2}>
                {this.state.imageUrl && (
                  <img
                    src={this.state.imageUrl}
                    alt="Uploaded Image"
                    // height="300"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <label htmlFor="upload-image">
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                    <input
                      id="upload-image"
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => this.handleFileUpload(e)}
                    />
                  </label>
                  <label htmlFor="delete-image">
                    <Button
                      variant="contained"
                      color="error"
                      component="span"
                      onClick={(e) => this.handleFileDelete(e)}
                    >
                      Delete
                    </Button>
                  </label>
                </div>
              </Stack> */}
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time !== -1 ? (
                  <p>
                    Prompt was generated in {this.state.generate_prompt_time} s.
                    Answer was generated in {this.state.generate_ans_time} s.
                    Speed is {this.state.speed} t/s
                  </p>
                ) : null}
              </div>
              <div>
                <h3>Previous conversations:</h3>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {this.state.buttonArr}
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3>Prompt looks like this:</h3>
                <textarea
                  readOnly={true}
                  rows={50}
                  style={{
                    marginTop: 0,
                    marginBottom: 50,
                    width: "100%",
                    borderRadius: 5,
                    padding: 5,
                  }}
                  color="primary"
                  value={this.state.prompt}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LLama32ChatComponent;
