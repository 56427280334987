import React from "react";
import { Message } from "react-chat-ui";
import { Button } from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
  NEW_CHATBOT_API_URL,
  URL_JSON_BACKEND_READ,
  URL_JSON_BACKEND_WRITE,
} from "../../../shared/constants/constants";
import {
  readChatJson,
  readPromptJson,
  saveChatJson,
  removeUnnecessaryResponseFromAIResponse,
  parseMarkdownTable,
  getChattingNameById,
  postData,
  getStoppingStringsForAIRsp,
  fromHistoryToString,
  parseMarkdownText,
  getRagWithHallucinationAnalysis,
} from "../../../shared/utils";
// import myUIConstants from "../../../shared/constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatComponentState,
  TChatbotResponseData,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";
import StaticBarDashboard from "../../../components/common/forms/StaticBar/StaticBarDashboard";
import ChatList from "../../../components/common/chats/ChatList";
import DropdownButton from "../../../components/common/dropdowns/Dropdown";

const CHAT_TYPE = 0;
const URL_CHAT = NEW_CHATBOT_API_URL;
const pwc_document_key_arr = ["pwc"];

type BackupObj = {
  timestamp: number;
  history: THistoryTurnOne[];
  prompt: string;
};

async function readBackupChatHistory(file_name: string): Promise<BackupObj[]> {
  const url = URL_JSON_BACKEND_READ;
  try {
    const backupRsp: { curBackup: BackupObj[] } = await postData(url, {
      file_name,
    });
    return backupRsp.curBackup;
  } catch (error) {
    return [];
  }
}

async function updateBackup(
  curBackup: BackupObj[],
  file_name: string
): Promise<void> {
  const url = URL_JSON_BACKEND_WRITE;
  await postData(url, { curBackup, file_name });
}

function backupChatHistory(
  history: THistoryTurnOne[],
  prompt: string,
  file_name: string,
  curBackup: BackupObj[]
): void {
  const backupObj: BackupObj = {
    timestamp: new Date().getTime(),
    history,
    prompt,
  };
  //dont save duplicates
  for (const oldObj of curBackup) {
    if (JSON.stringify(oldObj.history) === JSON.stringify(backupObj.history)) {
      return;
    }
  }
  curBackup.push(backupObj);
  updateBackup(curBackup, file_name);
}

// async function GetAIAnswerOld(
//   history: THistoryTurnOne[],
//   file_name_read_prompt: string,
//   document_key: string
// ): Promise<TChatbotResponseData> {
//   const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
//   const {
//     temperature,
//     top_p,
//     max_new_tokens,
//     chat_prompt_size,
//     repetition_penalty,
//     top_k,
//     ainame,
//     username,
//     embedding_chunk_size,
//   } = promptData;
//   const lastUserMsg = history[history.length - 1].message;
//   const chunkArr = await getRagChunks(
//     lastUserMsg,
//     embedding_chunk_size ? embedding_chunk_size : 4,
//     document_key
//   );
//   promptData.prompt = promptData.prompt.replace(
//     /{RAG_DOCUMENTS}/g,
//     chunkArr.join("\n")
//   );
//   promptData.prompt = promptData.prompt.replace(
//     /{CONVERSATION_HISTORY}/g,
//     fromHistoryToString(history, ainame, username)
//   );
//   promptData.prompt = promptData.prompt.replace(/{AINAME}/g, ainame);
//   promptData.prompt = promptData.prompt.replace(/{CHILDNAME}/g, username);
//   const rsp = await getChatGPTResponse({
//     prompt_text: promptData.prompt,
//     max_tokens: max_new_tokens,
//     top_p: top_p,
//     frequency_penalty: repetition_penalty,
//     presence_penalty: repetition_penalty,
//     engine: "gpt-4o-mini",
//     temperature: temperature,
//     ainame,
//     username,
//     stop_strings: getStoppingStringsForAIRsp(ainame, username),
//   });
//   const { output, generate_ans_time, speed } = rsp;
//   const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
//     output,
//     ainame,
//     username
//   );
//   return {
//     output: revisedAnswer,
//     prompt: promptData.prompt,
//     generate_ans_time,
//     generate_prompt_time: 0,
//     speed,
//   };
// }

async function GetAIAnswer(
  history: THistoryTurnOne[],
  file_name_read_prompt: string,
  document_key: string
): Promise<
  TChatbotResponseData & {
    hallucination_analysis: string;
    hallucination_probability: number;
    reasoning: string;
    is_used_long_context_llm: boolean;
  }
> {
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
    embedding_chunk_size,
  } = promptData;
  const lastUserMsg = history[history.length - 1].message;
  promptData.prompt = promptData.prompt.replace(
    /{CONVERSATION_HISTORY}/g,
    fromHistoryToString(history, ainame, username)
  );
  promptData.prompt = promptData.prompt.replace(/{AINAME}/g, ainame);
  promptData.prompt = promptData.prompt.replace(/{CHILDNAME}/g, username);
  const rsp = await getRagWithHallucinationAnalysis(
    {
      prompt_text: promptData.prompt,
      max_tokens: max_new_tokens,
      top_p: top_p,
      frequency_penalty: repetition_penalty,
      presence_penalty: repetition_penalty,
      engine: "gpt-4o-mini",
      temperature: temperature,
      ainame,
      username,
      stop_strings: getStoppingStringsForAIRsp(ainame, username),
    },
    lastUserMsg,
    document_key
  );
  const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
    rsp.answer,
    ainame,
    username
  );
  console.log(`rsp: ${JSON.stringify(rsp)}`);
  return {
    output: revisedAnswer,
    prompt: rsp.llm_prompt_text,
    generate_ans_time: 0,
    generate_prompt_time: 0,
    speed: 0,
    ...rsp,
  };
}

type PwcChatComponentState = ChatComponentState & {
  file_name_backup_history: string;
  isBackedAlready: boolean;
  buttonArr: any[];
  selectedDocumentKey: string;
  hallucination_analysis: string;
  hallucination_probability: number;
  reasoning: string;
  is_used_long_context_llm: boolean;
} & {
  page_index: number;
};

class PwcRagChatComponent extends React.Component<{}, PwcChatComponentState> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.testPwcRagChat,
      file_name_prompt: JSON_FILE_NAMES.testPwcRagPrompt,
      file_name_backup_history: JSON_FILE_NAMES.testPwcRagBackupHistory,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      isBackedAlready: false,
      buttonArr: [],
      input_message: "",
      selectedDocumentKey: pwc_document_key_arr[0],
      //hallucination analysis
      hallucination_analysis: "",
      hallucination_probability: 0,
      reasoning: "",
      // page control
      page_index: 1,
      is_used_long_context_llm: false,
    };
  }

  async componentDidMount() {
    const { history, prompt } = await readChatJson(this.state.file_name_chat);
    console.log(JSON.stringify(history));
    this.populateChatWindow(history, prompt);
    let document_key: string = pwc_document_key_arr[0];
    if (sessionStorage.getItem("document_key"))
      document_key = sessionStorage.getItem("document_key") as string;
    this.setState({
      buttonArr: await this.getBackupButtonArr(),
      selectedDocumentKey: document_key,
    });
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownText(parseMarkdownTable(historyTurn.message))}
              </ReactMarkdown>
              {historyTurn.data ? (
                <div className="hallucination-info">
                  <hr style={{ borderTop: "1px solid" }}></hr>
                  <ReactMarkdown
                    className="nomargin"
                    remarkPlugins={[remarkGfm]}
                  >
                    {parseMarkdownText(parseMarkdownTable(historyTurn.data))}
                  </ReactMarkdown>
                </div>
              ) : null}
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message.trim();
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    const {
      output,
      prompt,
      generate_ans_time,
      generate_prompt_time,
      speed,
      hallucination_analysis,
      hallucination_probability,
      reasoning,
      is_used_long_context_llm,
    } = await GetAIAnswer(
      this.state.history,
      this.state.file_name_prompt,
      this.state.selectedDocumentKey
    );
    // const answerWithHallucination = `${output}\n> 1. **Hallucination Analysis**: ${hallucination_analysis}\n> 2. **Probability of Hallucination**: ${hallucination_probability}%\n> 3. **Reasoning**: ${reasoning}`;
    this.pushEllaMessage(
      output,
      prompt,
      `1. **Hallucination Analysis**: ${hallucination_analysis}\n2. **Probability of Hallucination**: ${hallucination_probability}%\n3. **Reasoning**: ${reasoning}`
    );
    this.setState({
      generate_ans_time,
      generate_prompt_time,
      speed,
      hallucination_analysis,
      hallucination_probability,
      reasoning,
      is_used_long_context_llm,
    });
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string, data: any) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
        {data ? (
          <div className="hallucination-info">
            <hr style={{ borderTop: "1px solid" }}></hr>
            <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
              {parseMarkdownTable(data)}
            </ReactMarkdown>
          </div>
        ) : null}
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push({ ...ellaMessage, messageRawText: data });
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID, data });
    this.setState({ prompt, isBackedAlready: false });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  async onBackupDelete(e: any, timestamp: number) {
    const backupArr = await this.getBackupArr();
    const newArr: BackupObj[] = [];
    for (const obj of backupArr) {
      if (obj.timestamp !== timestamp) {
        newArr.push(obj);
      }
    }
    await updateBackup(newArr, this.state.file_name_backup_history);
    this.setState({ buttonArr: await this.getBackupButtonArr() });
  }

  onApplyBackupToChatWindow(
    e: any,
    history: THistoryTurnOne[],
    prompt: string
  ) {
    e.preventDefault();
    this.populateChatWindow(history, prompt);
  }

  async getBackupButtonArr(): Promise<any[]> {
    const backupArr = await this.getBackupArr();
    backupArr.sort((a, b) => b.timestamp - a.timestamp);
    const buttonArr: any[] = [];
    backupArr.forEach((backupObj) => {
      const { timestamp, history, prompt } = backupObj;
      buttonArr.push(
        <div>
          <Button
            onClick={(e) => this.onApplyBackupToChatWindow(e, history, prompt)}
            style={{ margin: 10, width: 400 }}
            type="submit"
            variant="outlined"
            color="success"
          >
            {new Date(timestamp).toISOString()}
          </Button>
          <Button
            onClick={(e) => this.onBackupDelete(e, timestamp)}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      );
    });
    return buttonArr;
  }

  async getBackupArr(): Promise<BackupObj[]> {
    const curBackupArr: BackupObj[] = await readBackupChatHistory(
      this.state.file_name_backup_history
    );
    return curBackupArr;
  }

  async onBackupCurrentChat(e: any) {
    e.preventDefault();
    const backupArr: BackupObj[] = await this.getBackupArr();
    backupChatHistory(
      this.state.history,
      this.state.prompt,
      this.state.file_name_backup_history,
      backupArr
    );
    this.setState({ isBackedAlready: true });
  }

  onDocumentChange(event: any) {
    event.preventDefault();
    this.setState({ selectedDocumentKey: event.target.value });
    sessionStorage.setItem("document_key", event.target.value);
  }
  onDocumentChangeNew(value: string) {
    this.setState({ selectedDocumentKey: value });
    sessionStorage.setItem("document_key", value);
  }

  // page control
  onClickTab(index: number) {
    this.setState({ page_index: index });
  }

  render() {
    return (
      <div className="chat-box-section " style={{ minWidth: 300 }}>
        <div className="single-settings-box profile-details-box overflow-hidden">
          <h3
            className="title pt-4"
            style={{
              textAlign: "center",
            }}
          >
            PwC Project
          </h3>
          <div className="profile-details-tab">
            <div className="advance-tab-button mb--30">
              <ul
                className="nav nav-tabs tab-button-style-2 justify-content-start"
                id="settinsTab-4"
                role="tablist"
              >
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 1}
                    onClick={() => this.onClickTab(1)}
                  >
                    <span className="title">Generate</span>
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#"
                    className={`tab-button ${
                      this.state.page_index === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected={this.state.page_index === 2}
                    onClick={() => this.onClickTab(2)}
                  >
                    <span className="title">Prompt Information</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane fade ${
              this.state.page_index === 1 && "active show"
            }`}
            role="tabpanel"
          >
            <ChatList
              message={this.state.messages}
              aiName={"Ella"}
              userName={"You"}
            />
            <StaticBarDashboard
              onSubmit={(e) => this.onMessageSubmit(e)}
              onChangeTextarea={(e) => {
                this.setState({ input_message: e.target.value });
              }}
              textareaValue={this.state.input_message}
              onBackupCurrentChat={(e) => this.onBackupCurrentChat(e)}
              onClearHistory={(e) => this.onClearHistory(e)}
            />
          </div>

          <div
            className={`tab-pane fade ${
              this.state.page_index === 2 && "active show"
            }`}
            role="tabpanel"
          >
            <div className="container" style={{ marginTop: 0 }}>
              <DropdownButton
                label="Selected Document"
                onSelect={(v) => this.onDocumentChangeNew(v)}
                select={this.state.selectedDocumentKey}
                selectData={pwc_document_key_arr}
              />
              <div
                style={{
                  direction: "rtl",
                  width: "100%",
                  padding: 10,
                  fontSize: 15,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {this.state.generate_ans_time !== -1 ? (
                  <p>
                    Prompt was generated in {this.state.generate_prompt_time} s.
                    Answer was generated in {this.state.generate_ans_time} s.
                    Speed is {this.state.speed} t/s
                  </p>
                ) : null}
              </div>
              <div>
                <h3>Previous conversations:</h3>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {this.state.buttonArr}
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3>Prompt looks like this:</h3>
                <textarea
                  readOnly={true}
                  rows={50}
                  style={{
                    marginTop: 0,
                    marginBottom: 50,
                    width: "100%",
                    borderRadius: 5,
                    padding: 5,
                  }}
                  color="primary"
                  value={this.state.prompt}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        {/* <div>
          <h5 style={{ color: "red" }}>
            Hallucination analysis: {this.state.hallucination_analysis}
          </h5>
          <h5 style={{ color: "red" }}>
            Hallucination probability: {this.state.hallucination_probability}%
          </h5>
          <h5 style={{ color: "red" }}>Reasoning: {this.state.reasoning}</h5>
          <h5 style={{ color: "red" }}>
            Used service:{" "}
            {this.state.is_used_long_context_llm ? "Long Context LLM" : "RAG"}
          </h5>
        </div> */}
      </div>
    );
  }
}

export default PwcRagChatComponent;
