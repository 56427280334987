import {
  AINAME_PLACEHOLDER,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  CHILDNAME_PLACEHOLDER,
  URL_JSON_BACKEND_READ,
  URL_JSON_BACKEND_WRITE,
} from "../constants/constants";

import {
  LLMServerResponse,
  TChatSavedJsonData,
  TCompareChatSavedJsonData,
  THistoryTurnOne,
  TPromptData,
} from "../types";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormData = require("form-data");

export async function postData(url = "", data = {}) {
  // Default options are marked with *
  const timeout = 1000 * 60 * 5;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    signal: controller.signal,
    body: JSON.stringify(data),
  });
  clearTimeout(id);
  return await response.json(); // parses JSON response into native JavaScript objects
}

export async function postFormData(url: string = "", data: any = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "POST",
      // "Access-Control-Allow-Headers": "Content-Type",
      // "Access-Control-Allow-Credentials": "true",
    },
    body: data,
  });
  return response; // parses JSON response into native JavaScript objects
}

export async function getData(url: string = "", data: any = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Credentials": "true",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

export async function readChatJson(
  file_name: string
): Promise<TChatSavedJsonData> {
  const url = URL_JSON_BACKEND_READ;
  const chatSavedData: TChatSavedJsonData = await postData(url, { file_name });
  return chatSavedData;
}

export async function readCompareChatJson(
  file_name: string
): Promise<TCompareChatSavedJsonData> {
  const url = URL_JSON_BACKEND_READ;
  const chatSavedData: TCompareChatSavedJsonData = await postData(url, {
    file_name,
  });
  return chatSavedData;
}

export async function readPromptJson(file_name: string): Promise<TPromptData> {
  const url = URL_JSON_BACKEND_READ;
  const promptData: TPromptData = await postData(url, {
    file_name,
  });
  return promptData;
}

export const saveChatJson = (
  history: THistoryTurnOne[],
  prompt: string,
  file_name: string,
  additional_data: any = {}
) => {
  const url = URL_JSON_BACKEND_WRITE;
  postData(url, { history, prompt, file_name, additional_data });
};

export const saveCompareChatJson = (
  history1: THistoryTurnOne[],
  history2: THistoryTurnOne[],
  prompt: string,
  file_name: string
) => {
  const url = URL_JSON_BACKEND_WRITE;
  postData(url, { history1, history2, prompt, file_name });
};

export const savePromptJson = (body_data: TPromptData, file_name: string) => {
  const url = URL_JSON_BACKEND_WRITE;
  const updated_body = {
    ...body_data,
    file_name,
  };
  postData(url, updated_body);
};

//returns substring that starts right after the startStr and ends right before the stopStr
export function getSubtext(
  startStr: string,
  stopStr: string,
  text: string
): string {
  let substr = "";
  let toInclude = false;
  let started = false;
  for (let i = 0; i < text.length; i++) {
    if (
      started &&
      text.substring(i, i + stopStr.length) === stopStr &&
      substr.trim().length > 0
    )
      break;
    if (text.substring(i, i + startStr.length) === startStr) {
      started = true;
      i += startStr.length - 1;
      toInclude = true;
    } else if (toInclude) {
      substr += text[i];
    }
  }
  substr = substr.trim();
  return substr;
}

export function capitalizeWords(inputString: string) {
  return inputString
    .toLowerCase()
    .replace(/(^|\s)\S/g, function (firstLetter: string) {
      return firstLetter.toUpperCase();
    });
}

export function capitalizeFirstLetter(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export async function getResponseFromLLMServer(
  url: string,
  bodyData: any,
  config: any = {}
): Promise<any> {
  const data = await axios
    .post<LLMServerResponse>(url, bodyData, config)
    .catch((error: any) => {
      console.error("LLM Server Error: ", error);
      if (error.code === "ECONNREFUSED") throw new Error(error.message);
      if (error.response?.data?.message && error.response?.data?.statusCode) {
        const message = error.response.data.message;
        // const statusCode = error.response.data.statusCode;
        if (message === "Prompt Not Found Error") {
          throw new Error(message);
        } else if (message === "Model Not Found Error") {
          throw new Error(message);
        }
        throw new Error(error.response.data.message);
      } else throw new Error(error.message);
    });
  return data.data.data;
}

export function constructFullPrompt(data: {
  prompt: string;
  aiName: string;
  childName: string;
  history: THistoryTurnOne[];
  lastUserMsg: string;
}): string {
  const { aiName, childName, history, lastUserMsg } = data;
  let prompt = data.prompt;
  let chatHistory = "";
  for (let i = 0; i < history.length; i++) {
    const histObj = history[i];
    const msg = histObj.message;
    const namePlaceholder =
      histObj.id === 0 ? CHILDNAME_PLACEHOLDER : AINAME_PLACEHOLDER;
    const curLine = `${namePlaceholder}: ${msg}${
      i < history.length - 1 ? "\n" : ""
    }`;
    chatHistory += curLine;
  }
  prompt = prompt.replace(/{CHAT_HISTORY}/g, chatHistory);
  prompt = prompt.replace(/{AINAME}/g, aiName);
  prompt = prompt.replace(/{CHILDNAME}/g, childName);
  prompt = prompt.replace(/{INPUT}/g, lastUserMsg);
  return prompt;
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function removeUnnecessaryResponseFromAIResponse(
  ans: string,
  aiName: string,
  userName: string
): string {
  ans = ans.replace(new RegExp(`${escapeRegExp(aiName)}: `, "g"), "");
  let startI = ans.indexOf(`${userName}: `);
  if (startI !== -1) {
    ans = ans.substring(0, startI);
  }
  let startIPhi3 = ans.indexOf(`<|end|>`);
  if (startIPhi3 !== -1) {
    ans = ans.substring(0, startIPhi3);
  }
  ans = ans.trim();
  ans = ans.replace(/\(MISSION \d+\)/, "");
  ans = ans.replace(/\[MISSION \d+\]/, "");
  ans = ans.replace(/\{MISSION \d+\}/, "");
  return ans;
}

export function parseMarkdownText(message: any) {
  if (message) {
    //remove multiple new lines (consider that they might have spaces)
    // message = message.replace(/(\s*\n\s*)+/g, "\n");
  }
  return message;
}

export function parseMarkdownTable(message: any) {
  return message;
  if (typeof message == "string") {
    const count = (message.match(/\|\s*\|/g) || []).length;
    if (count < 1) return message;
    message = message.replace(/\|\s*\|/g, "|\n|");
    message = message.replace(/\|/, "\n|");
    const lastPipeIndex = message.lastIndexOf("|");
    message =
      message.slice(0, lastPipeIndex + 1) +
      "\n\n" +
      message.slice(lastPipeIndex + 1);
    return message;
  }
  return message;
}

export function getChattingNameById(id: number): string {
  if (id === CHATTING_USER_MESSAGE_ID) return CHATTING_USER_NAME;
  return CHATTING_AI_NAME;
}

export function getStoppingStringsForAIRsp(
  ainame: string,
  username: string
): string[] {
  return [
    // `${ainame}:`,
    `${username}:`,
    // `${ainame}: `,
    `${username}: `,
    "<|end|>",
  ];
}

export function fromHistoryToString(
  history: THistoryTurnOne[],
  ainame: string,
  username: string
): string {
  let str = "";
  for (let i = 0; i < history.length; i++) {
    const { message, id } = history[i];
    str += `${id === CHATTING_USER_MESSAGE_ID ? username : ainame}: ${message}`;
    if (i !== history.length - 1) str += "\n";
  }
  return str;
}

export function fromHistoryToOldHistory(
  history: THistoryTurnOne[]
): string[][] {
  const newOldHistory: string[][] = [];
  for (let i = 0; i < history.length; i++) {
    const { message, id } = history[i];
    newOldHistory.push([
      id === CHATTING_USER_MESSAGE_ID
        ? CHILDNAME_PLACEHOLDER
        : AINAME_PLACEHOLDER,
      message,
    ]);
  }
  return newOldHistory;
}

export async function getRagChunks(
  text: string,
  chunk_len: number,
  document_key: string
): Promise<string[]> {
  const url = `${process.env.REACT_APP_LLM_SERVER_URL}/rag-test/get-rag-redirect`;
  const rsp: { chunk_arr: string[] } = await getResponseFromLLMServer(url, {
    text,
    chunk_len,
    document_key,
  });
  console.log(rsp.chunk_arr);
  return rsp.chunk_arr;
}

export async function getChatGPTResponse(data: {
  max_tokens: number;
  top_p: number;
  frequency_penalty: number;
  presence_penalty: number;
  engine: string;
  temperature: number;
  prompt_text: string;
  ainame: string;
  username: string;
  stop_strings: string[];
}): Promise<{ output: string; speed: number; generate_ans_time: number }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/rag-test/get-gpt-response`;
  const rsp: { output: string; speed: number; generate_ans_time: number } =
    await getResponseFromLLMServer(chatURL, data);
  return rsp;
}

export async function getHFLLamaResponse(data: {
  max_tokens: number;
  temperature: number;
  history: THistoryTurnOne[];
}): Promise<{ output: string; speed: number; generate_ans_time: number }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/dev/parser/redirect-llama`;
  const formData = new FormData();
  // if (image) formData.append("image", image);
  formData.append("max_new_tokens", data.max_tokens);
  formData.append("temperature", data.temperature);
  formData.append("history", JSON.stringify(data.history));
  const config = {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  };
  const rsp: { output: string; speed: number; generate_ans_time: number } =
    await getResponseFromLLMServer(chatURL, formData, config);
  return rsp;
}

export async function getLLamaVertexAIVisionResponse(
  data: {
    max_tokens: number;
    temperature: number;
    history: THistoryTurnOne[];
  },
  image: any
): Promise<{ output: string; speed: number; generate_ans_time: number }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/dev/parser/vertex-api`;
  const formData = new FormData();
  if (image) formData.append("image", image);
  formData.append("max_new_tokens", data.max_tokens);
  formData.append("temperature", data.temperature);
  formData.append("history", JSON.stringify(data.history));
  const config = {
    headers: {
      "Content-Type": `multipart/form-data`,
    },
  };
  const rsp: { output: string; speed: number; generate_ans_time: number } =
    await getResponseFromLLMServer(chatURL, formData, config);
  return rsp;
}

export async function getLLamaFireworksAITextResponse(data: {
  max_tokens: number;
  temperature: number;
  history: THistoryTurnOne[];
}): Promise<{ output: string; speed: number; generate_ans_time: number }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/dev/parser/fireworks-api/text`;
  // const formData = new FormData();
  const jsonData = {
    max_new_tokens: JSON.stringify(data.max_tokens),
    temperature: JSON.stringify(data.temperature),
    history: JSON.stringify(data.history),
  };
  // formData.append("max_new_tokens", data.max_tokens);
  // formData.append("temperature", data.temperature);
  // formData.append("history", JSON.stringify(data.history));
  const config = {
    // headers: {
    //   "Content-Type": `multipart/form-data`,
    // },
  };
  const rsp: { output: string; speed: number; generate_ans_time: number } =
    await getResponseFromLLMServer(chatURL, jsonData, config);
  return rsp;
}

export async function getChatGPTResponseDocument(data: {
  prompt_text: string;
}): Promise<{ output: string; speed: number; generate_ans_time: number }> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/rag-test/get-gpt-response-document`;
  const rsp: { output: string; speed: number; generate_ans_time: number } =
    await getResponseFromLLMServer(chatURL, data);
  return rsp;
}

export async function getRagWithHallucinationAnalysis(
  data: {
    max_tokens: number;
    top_p: number;
    frequency_penalty: number;
    presence_penalty: number;
    engine: string;
    temperature: number;
    prompt_text: string;
    ainame: string;
    username: string;
    stop_strings: string[];
  },
  query: string,
  document_key: string
): Promise<{
  answer: string;
  hallucination_analysis: string;
  hallucination_probability: number;
  reasoning: string;
  llm_prompt_text: string;
  is_used_long_context_llm: boolean;
}> {
  const chatURL = `${process.env.REACT_APP_LLM_SERVER_URL}/rag-test/get-rag-with-hallucination-analysis`;
  const rsp: {
    answer: string;
    hallucination_analysis: string;
    hallucination_probability: number;
    reasoning: string;
    is_used_long_context_llm: boolean;
    llm_prompt_text: string;
  } = await getResponseFromLLMServer(chatURL, {
    prompt_data: data,
    query,
    document_key,
  });
  return rsp;
}

export type BackupObj = {
  timestamp: number;
  history: THistoryTurnOne[];
  prompt: string;
};

export async function readBackupChatHistory(
  file_name: string
): Promise<BackupObj[]> {
  const url = URL_JSON_BACKEND_READ;
  try {
    const backupRsp: { curBackup: BackupObj[] } = await postData(url, {
      file_name,
    });
    return backupRsp.curBackup ? backupRsp.curBackup : [];
  } catch (error) {
    return [];
  }
}

export async function updateBackup(
  curBackup: BackupObj[],
  file_name: string
): Promise<void> {
  const url = URL_JSON_BACKEND_WRITE;
  await postData(url, { curBackup, file_name });
}

export function backupChatHistory(
  history: THistoryTurnOne[],
  prompt: string,
  file_name: string,
  curBackup: BackupObj[]
): void {
  const backupObj: BackupObj = {
    timestamp: new Date().getTime(),
    history,
    prompt,
  };
  //dont save duplicates
  for (const oldObj of curBackup) {
    if (JSON.stringify(oldObj.history) === JSON.stringify(backupObj.history)) {
      return;
    }
  }
  curBackup.push(backupObj);
  updateBackup(curBackup, file_name);
}
