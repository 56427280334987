import { TChatMessageElement } from "@/shared/types";
import React from "react";
import ReactMarkdown from "react-markdown";

type TChatListProps = {
  message: TChatMessageElement[];
  aiName: string;
  userName: string;
};
type TChatItemProps = {
  item: TChatMessageElement;
  aiName: string;
  userName: string;
  isLast?: boolean;
};
function textContent(elem: React.ReactElement | string): string {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join("");
  }
  return textContent(children);
}

const ChatItem = (props: TChatItemProps) => {
  const itemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (props.isLast && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.isLast]);
  return (
    <div className="chat-box-list pb-0" ref={itemRef}>
      <div className="chat-box author-speech">
        <div
          className="inner"
          style={
            props.item.senderName !== props.aiName
              ? {
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "right",
                  alignItems: "baseline",
                }
              : {}
          }
        >
          <div
            className={`chat-section ${
              props.item.senderName !== props.aiName && "user-chat-box"
            }`}
            style={{
              // paddingTop: 20,
              maxWidth:
                props.item.senderName !== props.aiName ? "90%" : undefined,
            }}
          >
            <div className="chat-content content-marks">
              {props.item.senderName &&
                props.item.senderName === props.aiName && (
                  <h6 className="title" style={{ marginBottom: 10 }}>
                    {props.item.senderName}
                    <span className="rainbow-badge-card">
                      <i className="fa-sharp fa-regular fa-check"></i>
                      {"bot"}
                    </span>
                    <button
                      style={{
                        margin: 10,
                        fontSize: 10,
                        // backgroundColor: "",
                      }}
                      onClick={() => {
                        const rawText = props.item.messageRawText
                          ? props.item.messageRawText
                          : textContent(props.item.message);
                        navigator.clipboard.writeText(rawText);
                      }}
                    >
                      copy
                    </button>
                  </h6>
                )}

              {typeof props.item.message === "string" ? (
                <ReactMarkdown children={props.item.message} />
              ) : (
                props.item.message
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatList = ({ message, aiName, userName }: TChatListProps) => {
  return (
    <>
      {message &&
        message.map((data, index) => {
          const isLast = index === message.length - 1;
          return (
            <ChatItem
              key={index}
              item={data}
              aiName={aiName}
              userName={userName}
              isLast={isLast}
            />
          );
        })}
    </>
  );
};

export default ChatList;
