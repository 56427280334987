import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import GrammarlyPageLayout from "../pages/grammarly/GrammarlyPageLayout";
import MyCustomGrammarly from "../pages/grammarly/MyCustomGrammarly";
import GrammarlyIndex from "../pages/grammarly/GrammarlyIndex";
import ChatPageLayout from "../pages/langchain_llm/ChatPageLayout";
import ChatIndex from "../pages/langchain_llm/ChatIndex";
import PlainChatIndex from "../pages/langchain_llm/plain/PlainChatIndex";
import PlainChatPageLayout from "../pages/langchain_llm/plain/PlainChatPageLayout";
import PlainPrompt from "../pages/langchain_llm/plain/PlainPrompt";
import RoleplayChatPageLayout from "../pages/langchain_llm/roleplay/RoleplayPageLayout";
import RoleplayChatIndex from "../pages/langchain_llm/roleplay/RoleplayChatIndex copy";
import RoleplayPrompt from "../pages/langchain_llm/roleplay/RoleplayPrompt";
import RoleplayChat from "../pages/langchain_llm/roleplay/RoleplayChat";
import MemoryChatPageLayout from "../pages/langchain_llm/memory/MemoryChatPageLayout";
import MemoryChatIndex from "../pages/langchain_llm/memory/MemoryChatIndex";
import MemoryChat from "../pages/langchain_llm/memory/MemoryChat";
import MemoryPrompt from "../pages/langchain_llm/memory/MemoryPrompt";
import PdfChat from "../pages/langchain_llm/pdf/PdfChat";
import PdfPrompt from "../pages/langchain_llm/pdf/PdfPrompt";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import BlipPageLayout from "../pages/blip-image/BlipPageLayout";
import BlipIndex from "../pages/blip-image/BlipIndex";
import Blip from "../pages/blip-image/Blip";
import PlainChat from "../pages/langchain_llm/plain/PlainChat";
import BlipPrompt from "../pages/blip-image/BlipPrompt";
import ChainOfThoughtChatPageLayout from "../pages/langchain_llm/chain0of-htought/ChainOfThoughtChatPageLayout";
import ChainOfThoughtChatIndex from "../pages/langchain_llm/chain0of-htought/ChainOfThoughtChatIndex";
import ChainOfThoughtChat from "../pages/langchain_llm/chain0of-htought/ChainOfThoughtChat";
import ChainOfThoughtPrompt from "../pages/langchain_llm/chain0of-htought/ChainOfThoughtPrompt";
import ImageGenerationChatPageLayout from "../pages/image-generation/ImageGenerationChatPageLayout";
import ImageGenerationChat from "../pages/image-generation/ImageGenerationChat";
import ImageGenerationPrompt from "../pages/image-generation/ImageGenerationPrompt";
import TTSPageLayout from "../pages/tts/TTSPageLayout";
import TTSIndex from "../pages/tts/TTSIndex";
import TTSWhisper from "../pages/tts/TTS-whisper";
import TTSGoogle from "../pages/tts/TTS-google";
import MyCustomGrammarlyV2 from "../pages/grammarly/MyCustomGrammarlyImproved";
import TTSMelo from "../pages/tts/TTS-melo";
import YanadooRagChatPageLayout from "../pages/langchain_llm/yanadoo-rag/YanadooRagChatPageLayout";
import YanadooRagChatIndex from "../pages/langchain_llm/yanadoo-rag/YanadooRagIndex";
import YanadooRagChat from "../pages/langchain_llm/yanadoo-rag/YanadooRag";
import YanadooRagPrompt from "../pages/langchain_llm/yanadoo-rag/YandooRagPrompt";
import CompareChatPageLayout from "../pages/langchain_llm/comparison/CompareChatPageLayout";
import CompareChatIndex from "../pages/langchain_llm/comparison/CompareChatIndex";
import CompareChat from "../pages/langchain_llm/comparison/CompareChat";
import ComparePrompt from "../pages/langchain_llm/comparison/ComparePrompt";
import React from "react";
import KipaRagChatIndex from "pages/langchain_llm/kipa-rag/KipaRagIndex";

import KipaRagChat from "pages/langchain_llm/kipa-rag/KipaRag";
import KipaRagPrompt from "pages/langchain_llm/kipa-rag/KipaRagPrompt";
import PwcRagChatPageLayout from "pages/langchain_llm/pwc/PwcRagChatPageLayout";
import PwcRagChatIndex from "pages/langchain_llm/pwc/PwcRagIndex";
import PwcRagChat from "pages/langchain_llm/pwc/PwcRag";
import PwcRagPrompt from "pages/langchain_llm/pwc/PwcRagPrompt";
import NHRagChat from "pages/langchain_llm/nh/NHRag";
import NHRagPrompt from "pages/langchain_llm/nh/NHRagPrompt";
import NHRagChatIndex from "pages/langchain_llm/nh/NHRagIndex";
import NHRagChatPageLayout from "pages/langchain_llm/nh/NHRagChatPageLayout";
import SpeechAnalysis from "pages/speech-analysis/SpeechAnalysis";
import SpeechAnalysisWithTopic from "pages/speech-analysis/SpeechAnalysisWithTopic";
import PwcRagDocument from "pages/langchain_llm/pwc/PwcRagDocument";
import CommonChatPageLayout from "../pages/langchain_llm/ChatPageLayout";
import LLama32BigPrompt from "../pages/langchain_llm/vision-llama-90/LLamaVisionPrompt";
import LLama32BigChat from "../pages/langchain_llm/vision-llama-90/LLamaVision";
import LLama32BigChatIndex from "../pages/langchain_llm/vision-llama-90/LLamaVisionIndex";
import LLama32Prompt from "../pages/langchain_llm/text-llama-70/LLamaTextPrompt";
import LLama32Chat from "../pages/langchain_llm/text-llama-70/LLamaText";
import LLama32ChatIndex from "../pages/langchain_llm/text-llama-70/LLamaTextIndex";

export const defaultAppRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },
];
export const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },
  {
    path: "/saas",
    element: <GrammarlyPageLayout />,
    state: "saas",
    sidebarProps: {
      displayText: "SaaS API",
      // icon: <ArticleOutlinedIcon />,
      icon: (
        <img src={require("../tools/images/generator-icon/document.png")} />
      ),
    },
    child: [
      {
        index: true,
        element: <GrammarlyIndex />,
        state: "saas.index",
      },
      {
        path: "/saas/grammarly",
        element: <GrammarlyPageLayout />,
        state: "saas.grammarly",
        sidebarProps: {
          displayText: "Grammar Correction",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <GrammarlyIndex />,
            state: "saas.grammarly.index",
          },
          {
            path: "/saas/grammarly/custom",
            element: <MyCustomGrammarly />,
            state: "saas.grammarly.custom",
            sidebarProps: {
              displayText: "Grammar Correction API",
            },
          },
          {
            path: "/saas/grammarly/custom/v2",
            element: <MyCustomGrammarlyV2 />,
            state: "saas.grammarly.custom.v2",
            sidebarProps: {
              displayText: "Grammar Correction Improved API",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/chatting",
    element: <ChatPageLayout />,
    state: "chatting",
    sidebarProps: {
      displayText: "Finetuned LLM",
      icon: (
        <img src={require("../tools/images/generator-icon/document.png")} />
      ),
    },
    child: [
      {
        index: true,
        element: <ChatIndex />,
        state: "chatting.index",
      },
      {
        path: "/chatting/plain",
        element: <PlainChatPageLayout />,
        state: "chatting.plain",
        sidebarProps: {
          displayText: "Original LLM",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <PlainChatIndex />,
            state: "chatting.plain.index",
          },
          {
            path: "/chatting/plain/chat",
            element: <PlainChat />,
            state: "chatting.plain.chat",
            sidebarProps: {
              displayText: "Free Chat",
            },
          },
          {
            path: "/chatting/plain/prompt",
            element: <PlainPrompt />,
            state: "chatting.plain.prompt",
            sidebarProps: {
              displayText: "Free Chat Prompt",
            },
          },
        ],
      },
      {
        path: "/chatting/chain-of-thought",
        element: <ChainOfThoughtChatPageLayout />,
        state: "chatting.chain-of-thought",
        sidebarProps: {
          displayText: "Chain Of Thought",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <ChainOfThoughtChatIndex />,
            state: "chatting.chain-of-thought.index",
          },
          {
            path: "/chatting/chain-of-thought/chat",
            element: <ChainOfThoughtChat />,
            state: "chatting.chain-of-thought.chat",
            sidebarProps: {
              displayText: "Chain Of Thought Chat",
            },
          },
          {
            path: "/chatting/chain-of-thought/prompt",
            element: <ChainOfThoughtPrompt />,
            state: "chatting.chain-of-thought.prompt",
            sidebarProps: {
              displayText: "Chain Of Thought Prompt",
            },
          },
        ],
      },
      {
        path: "/chatting/roleplay",
        element: <RoleplayChatPageLayout />,
        state: "chatting.roleplay",
        sidebarProps: {
          displayText: "Roleplay",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <RoleplayChatIndex />,
            state: "chatting.roleplay.index",
          },
          {
            path: "/chatting/roleplay/chat",
            element: <RoleplayChat />,
            state: "chatting.roleplay.chat",
            sidebarProps: {
              displayText: "Roleplay Chat",
            },
          },
          {
            path: "/chatting/roleplay/prompt",
            element: <RoleplayPrompt />,
            state: "chatting.roleplay.prompt",
            sidebarProps: {
              displayText: "Roleplay Prompt",
            },
          },
        ],
      },
      {
        path: "/chatting/yanadoo_rag",
        element: <YanadooRagChatPageLayout />,
        state: "chatting.yanadoo_rag",
        sidebarProps: {
          displayText: "Yanadoo CS Chatbot",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <YanadooRagChatIndex />,
            state: "chatting.yanadoo_rag.index",
          },
          {
            path: "/chatting/yanadoo_rag/chat",
            element: <YanadooRagChat />,
            state: "chatting.yanadoo_rag.chat",
            sidebarProps: {
              displayText: "Yanadoo CS Chatbot",
            },
          },
          {
            path: "/chatting/yanadoo_rag/prompt",
            element: <YanadooRagPrompt />,
            state: "chatting.yanadoo_rag.prompt",
            sidebarProps: {
              displayText: "Yanadoo CS Chatbot Prep",
            },
          },
        ],
      },
      {
        path: "/chatting/kipa_rag",
        element: <CommonChatPageLayout />,
        state: "chatting.kipa_rag",
        sidebarProps: {
          displayText: "KIPA AI Capacity Builder",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <KipaRagChatIndex />,
            state: "chatting.kipa_rag.index",
          },
          {
            path: "/chatting/kipa_rag/chat",
            element: <KipaRagChat />,
            state: "chatting.kipa_rag.chat",
            sidebarProps: {
              displayText: "KIPA AI Capacity Builder",
            },
          },
          {
            path: "/chatting/kipa_rag/prompt",
            element: <KipaRagPrompt />,
            state: "chatting.kipa_rag.prompt",
            sidebarProps: {
              displayText: "KIPA AI Capacity Builder",
            },
          },
        ],
      },
      {
        path: "/chatting/llama32",
        element: <CommonChatPageLayout />,
        state: "chatting.llama32",
        sidebarProps: {
          displayText: "Samsung E&A - Text LLM",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <LLama32ChatIndex />,
            state: "chatting.llama32.index",
          },
          {
            path: "/chatting/llama32/chat",
            element: <LLama32Chat />,
            state: "chatting.llama32.chat",
            sidebarProps: {
              displayText: "Text LLM Chatting",
            },
          },
          {
            path: "/chatting/llama32/prompt",
            element: <LLama32Prompt />,
            state: "chatting.llama32.prompt",
            sidebarProps: {
              displayText: "Text LLM Prompt",
            },
          },
        ],
      },
      {
        path: "/chatting/llama32_big",
        element: <CommonChatPageLayout />,
        state: "chatting.llama32_big",
        sidebarProps: {
          displayText: "Samsung E&A - Vision LLM",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <LLama32BigChatIndex />,
            state: "chatting.llama32_big.index",
          },
          {
            path: "/chatting/llama32_big/chat",
            element: <LLama32BigChat />,
            state: "chatting.llama32_big.chat",
            sidebarProps: {
              displayText: "Vision LLM Chatting",
            },
          },
          {
            path: "/chatting/llama32_big/prompt",
            element: <LLama32BigPrompt />,
            state: "chatting.llama32_big.prompt",
            sidebarProps: {
              displayText: "Vision LLM Prompt",
            },
          },
        ],
      },
      {
        path: "/chatting/pwc_rag",
        element: <PwcRagChatPageLayout />,
        state: "chatting.pwc_rag",
        sidebarProps: {
          displayText: "PwC Project",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <PwcRagChatIndex />,
            state: "chatting.pwc_rag.index",
          },
          {
            path: "/chatting/pwc_rag/chat",
            element: <PwcRagChat />,
            state: "chatting.pwc_rag.chat",
            sidebarProps: {
              displayText: "PwC Project",
            },
          },
          {
            path: "/chatting/pwc_rag/prompt",
            element: <PwcRagPrompt />,
            state: "chatting.pwc_rag.prompt",
            sidebarProps: {
              displayText: "PwC Project Prep",
            },
          },
          {
            path: "/chatting/pwc_rag/document",
            element: <PwcRagDocument />,
            state: "chatting.pwc_rag.document",
            sidebarProps: {
              displayText: "PwC Design Interpretation",
            },
          },
        ],
      },

      {
        path: "/chatting/nh_rag",
        element: <NHRagChatPageLayout />,
        state: "chatting.nh_rag",
        sidebarProps: {
          displayText: "NH AI ConText Parser",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <NHRagChatIndex />,
            state: "chatting.nh_rag.index",
          },
          {
            path: "/chatting/nh_rag/chat",
            element: <NHRagChat />,
            state: "chatting.nh_rag.chat",
            sidebarProps: {
              displayText: "NH AI ConText Parser",
            },
          },
          {
            path: "/chatting/nh_rag/prompt",
            element: <NHRagPrompt />,
            state: "chatting.nh_rag.prompt",
            sidebarProps: {
              displayText: "NH AI ConText Parseer Prep",
            },
          },
        ],
      },
      {
        path: "/chatting/memory",
        element: <MemoryChatPageLayout />,
        state: "chatting.memory",
        sidebarProps: {
          displayText: "Episodic Memory (RAG)",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <MemoryChatIndex />,
            state: "chatting.memory.index",
          },
          {
            path: "/chatting/memory/chat",
            element: <MemoryChat />,
            state: "chatting.memory.chat",
            sidebarProps: {
              displayText: "Episodic Memory Chat (RAG)",
            },
          },
          {
            path: "/chatting/memory/prompt",
            element: <MemoryPrompt />,
            state: "chatting.memory.prompt",
            sidebarProps: {
              displayText: "Episodic Memory Prompt (RAG)",
            },
          },
        ],
      },
      {
        path: "/chatting/pdf",
        element: <ChatPageLayout />,
        state: "chatting.pdf",
        sidebarProps: {
          displayText: "Story Tuned (RAG)",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <ChatIndex />,
            state: "chatting.pdf.index",
          },
          {
            path: "/chatting/pdf/chat",
            element: <PdfChat />,
            state: "chatting.pdf.chat",
            sidebarProps: {
              displayText: "Story Tuned Chat (RAG)",
            },
          },
          {
            path: "/chatting/pdf/prompt",
            element: <PdfPrompt />,
            state: "chatting.pdf.prompt",
            sidebarProps: {
              displayText: "Story Tuned Prompt (RAG)",
            },
          },
        ],
      },
      {
        path: "/chatting/compare",
        element: <CompareChatPageLayout />,
        state: "chatting.compare",
        sidebarProps: {
          displayText: "Comparison Page",
          icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        },
        child: [
          {
            index: true,
            element: <CompareChatIndex />,
            state: "chatting.compare.index",
          },
          {
            path: "/chatting/compare/chat",
            element: <CompareChat />,
            state: "chatting.compare.chat",
            sidebarProps: {
              displayText: "Comparison Chat Page",
            },
          },
          {
            path: "/chatting/compare/prompt",
            element: <ComparePrompt />,
            state: "chatting.compare.prompt",
            sidebarProps: {
              displayText: "Comparison Prompt Page",
            },
          },
        ],
      },
      // {
      //   path: "/chatting/image-generation",
      //   element: <ImageGenerationChatPageLayout />,
      //   state: "chatting.image-generation",
      //   sidebarProps: {
      //     displayText: "Image Generation (Stable Diffusion 3)",
      //     icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
      //   },
      //   child: [
      //     {
      //       index: true,
      //       element: <ChatIndex />,
      //       state: "chatting.image-generation.index",
      //     },
      //     {
      //       path: "/chatting/image-generation/chat",
      //       element: <ImageGenerationChat />,
      //       state: "chatting.image-generation.chat",
      //       sidebarProps: {
      //         displayText: "Image Generation Chat",
      //       },
      //     },
      //     {
      //       path: "/chatting/image-generation/prompt",
      //       element: <ImageGenerationPrompt />,
      //       state: "chatting.image-generation.prompt",
      //       sidebarProps: {
      //         displayText: "Image Generation Prompt",
      //       },
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/image-generation",
    element: <ImageGenerationChatPageLayout />,
    state: "image-generation",
    sidebarProps: {
      displayText: "Image Generation",
      icon: (
        <img src={require("../tools/images/generator-icon/document.png")} />
      ),
    },
    child: [
      {
        index: true,
        element: <ChatIndex />,
        state: "image-generation.index",
      },
      {
        path: "/image-generation/chat",
        element: <ImageGenerationChat />,
        state: "image-generation.chat",
        sidebarProps: {
          displayText: "Image Generation Chat",
        },
      },
      {
        path: "/image-generation/prompt",
        element: <ImageGenerationPrompt />,
        state: "image-generation.prompt",
        sidebarProps: {
          displayText: "Image Generation Prompt",
        },
      },
    ],
  },
  {
    path: "/blip",
    element: <BlipPageLayout />,
    state: "blip",
    sidebarProps: {
      displayText: "Image Interpretation",
      icon: (
        <img src={require("../tools/images/generator-icon/document.png")} />
      ),
    },
    child: [
      {
        index: true,
        element: <BlipIndex />,
        state: "blip.index",
      },
      {
        path: "/blip/image",
        element: <Blip />,
        state: "blip.image",
        sidebarProps: {
          displayText: "Image Interpretation",
        },
      },
      {
        path: "/blip/image/prompt",
        element: <BlipPrompt />,
        state: "blip.image.prompt",
        sidebarProps: {
          displayText: "Image Interpretation Prompt",
        },
      },
    ],
  },
  {
    path: "/tts",
    element: <TTSPageLayout />,
    state: "tts",
    sidebarProps: {
      displayText: "TTS",
      icon: <img src={require("../tools/images/generator-icon/voice.png")} />,
    },
    child: [
      {
        index: true,
        element: <TTSIndex />,
        state: "tts.index",
      },
      {
        path: "/tts/openai",
        element: <TTSWhisper />,
        state: "tts.openai",
        sidebarProps: {
          displayText: "Whisper TTS",
        },
      },
      {
        path: "/tts/google",
        element: <TTSGoogle />,
        state: "tts.google",
        sidebarProps: {
          displayText: "Google TTS",
        },
      },
      {
        path: "/tts/melo",
        element: <TTSMelo />,
        state: "tts.melo",
        sidebarProps: {
          displayText: "Custom TTS (Melo)",
        },
      },
    ],
  },
  {
    path: "/speech-analysis",
    element: <TTSPageLayout />,
    state: "speech-analysis",
    sidebarProps: {
      displayText: "Speech Analysis",
      icon: <img src={require("../tools/images/generator-icon/voice.png")} />,
    },
    child: [
      {
        path: "/speech-analysis/index",
        element: <SpeechAnalysis />,
        state: "speech-analysis.index",
        sidebarProps: {
          displayText: "Speech Analysis with Text",
        },
      },
      {
        path: "/speech-analysis/topic",
        element: <SpeechAnalysisWithTopic />,
        state: "speech-analysis.topic",
        sidebarProps: {
          displayText: "Speech Analysis with Topic",
        },
      },
    ],
  },
  // {
  //   path: "/test",
  //   element: <Tester />,
  //   state: "test",
  //   sidebarProps: {
  //     displayText: "test test",
  //     icon: <ArticleOutlinedIcon />,
  //   },
  // },
];
