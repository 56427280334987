import React from "react";
import LLama32BigChatComponent from "./LLamaVisionComponent";

export default function LLama32BigChat() {
  return (
    <div className="App">
      <LLama32BigChatComponent />
    </div>
  );
}
