import React from "react";
import { Tooltip } from "react-tooltip";
type TStaticBarFormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  textareaValue: string;
  onChangeTextarea: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  buttonDisabled?: boolean;
  onClearHistory: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBackupCurrentChat?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClearMemory?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const StaticBarForm = ({
  textareaValue,
  onChangeTextarea,

  buttonDisabled,
  onSubmit,
  onBackupCurrentChat,
  onClearHistory,
  onClearMemory,
}: TStaticBarFormProps) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [textareaHeight, setTextareaHeight] = React.useState<{
    row: number;
    lineBreak: { [key: string]: boolean };
  }>({
    row: 1,
    lineBreak: {},
  });

  const resizeTextarea = () => {
    if (textareaRef.current) {
      console.log(
        "resize ::: textareaRef.current.scrollHeight :",
        textareaRef.current.scrollHeight
      );

      textareaRef.current.style.height = "auto"; // 기존 높이 초기화
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 새로운 높이 설정
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(e);
    if (textareaRef.current) {
      // 텍스트 영역 높이 초기화
      setTextareaHeight({ row: 1, lineBreak: {} }); // 초기값으로 리셋
    }
  };
  const onKeyEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    if (!e.shiftKey && e.code === "Enter") {
      if (value !== "") {
        setTextareaHeight((prev) => ({
          row: prev.row + 1,
          lineBreak: { ...prev.lineBreak, [`${value.length}`]: true },
        }));
      }
    }
  };

  React.useEffect(() => {
    if (
      textareaHeight.row === 1 &&
      Object.keys(textareaHeight.lineBreak).length === 0
    ) {
      resizeTextarea();
    }
  }, [textareaHeight]);

  return (
    <>
      <Tooltip id="my-tooltip" className="custom-tooltip tooltip-inner" />
      <form
        ref={formRef}
        className="new-chat-form-custom border-gradient"
        onSubmit={(e) => handleSubmit(e)}
      >
        <textarea
          ref={textareaRef}
          className="text-wrap"
          rows={textareaHeight.row}
          onKeyDown={(e) => onKeyEnter(e)}
          onInput={(e) => resizeTextarea(e)}
          placeholder="Send a message..."
          value={textareaValue}
          onChange={onChangeTextarea}
        ></textarea>

        <div className="right-icons">
          {onBackupCurrentChat && (
            <div
              className="form-icon icon-plus"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Backup Current Chat"
              onClick={(e) => onBackupCurrentChat(e)}
            >
              <i className="fa-sharp fa-solid fa-copy"></i>
            </div>
          )}
          {onClearMemory && (
            <div
              className="form-icon icon-plus"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Clear Memory"
              onClick={(e) => onClearMemory(e)}
            >
              <i className="fa-sharp fa-solid fa-trash"></i>
            </div>
          )}
          <div
            className="form-icon icon-mic"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Clear History"
            onClick={(e) => onClearHistory(e)}
          >
            <i className="feather-repeat"></i>
          </div>
          <button
            className="form-icon icon-send"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Send message"
            type="submit"
            id="staticbar-form-submit-button"
            ref={buttonRef}
          >
            <i className="fa-sharp fa-solid fa-paper-plane-top"></i>
          </button>
        </div>
      </form>
    </>
  );
};
export default StaticBarForm;
