import { useState } from "react";
import React from "react";
import { TPromptData } from "shared/types";
import Form from "../../../components/common/forms/Form";
import TextareaForm from "../../../components/common/forms/TextareaForm";
import RangeInputForm from "../../../components/common/forms/RangeInputForm";
import TextInputForm from "../../../components/common/forms/TextInputForm";
import PageTitleForm from "../../../components/common/forms/PageTitleForm";
// require('dotenv').config()

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

function send(
  prompt: string,
  temperature: number,
  top_p: number,
  max_new_tokens: number,
  chat_prompt_size: number,
  repetition_penalty: number,
  top_k: number,
  ainame: string,
  username: string,
  chains: string[]
) {
  postData(
    `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write/chain-of-thought/prompt`,
    {
      prompt,
      temperature,
      top_p,
      max_new_tokens,
      chat_prompt_size,
      repetition_penalty,
      top_k,
      ainame,
      username,
      chains,
    }
  );
}

function ChainOfThoughtPrompt() {
  const text =
    "your input must be {QUESTION}, each added box needs to have the answer from the previous box {ANSWER_i}";
  const [prompt, set_prompt] = useState("");
  const [top_p, set_top_p] = useState(-1);
  const [temperature, set_temperature] = useState(-1);
  const [chat_prompt_size, set_chat_prompt_size] = useState(-1);
  const [max_new_tokens, set_max_new_tokens] = useState(-1);
  const [repetition_penalty, set_repetition_penalty] = useState(-1);
  const [top_k, set_top_k] = useState(-1);
  const [received, set_received] = useState(false);
  const [username, set_username] = useState("");
  const [ainame, set_ainame] = useState("");
  const [chains, set_chains] = useState([""]);
  if (!received)
    getData(
      `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/chain-of-thought/prompt`
    )
      .then((rsp) => rsp.json())
      .then((data: TPromptData) => {
        set_prompt(data.prompt);
        set_top_p(data.top_p);
        set_temperature(data.temperature);
        set_chat_prompt_size(data.chat_prompt_size);
        set_max_new_tokens(data.max_new_tokens);
        set_repetition_penalty(data.repetition_penalty);
        set_top_k(data.top_k);
        set_received(true);
        set_ainame(data.ainame);
        set_username(data.username);
        set_chains(data.chains);
      });
  return (
    <div className="chat-box-section " style={{ minWidth: 300 }}>
      <PageTitleForm title={"Chain Of Thought Prompt"} descriptionText={text} />

      <div
        className="rbt-static-bar-top"
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            send(
              prompt,
              temperature,
              top_p,
              max_new_tokens,
              chat_prompt_size,
              repetition_penalty,
              top_k,
              ainame,
              username,
              chains
            );
          }}
        >
          <TextareaForm
            rows={20}
            textareaValue={prompt}
            onChangeTextarea={(event) => {
              set_prompt(event.target.value);
            }}
          />

          {chains.map((chain, ind) => (
            <div
              className="textareaRow"
              key={`chain-of-thought-first-map-${ind}`}
            >
              <TextareaForm
                rows={10}
                textareaValue={chain}
                onChangeTextarea={(event) => {
                  set_chains([
                    ...chains.slice(0, ind),
                    event.target.value,
                    ...chains.slice(ind + 1),
                  ]);
                }}
              />
              <div className="buttonsCol">
                <button
                  className="btn-default add"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    set_chains([
                      ...chains.slice(0, ind + 1),
                      "",
                      ...chains.slice(ind + 1),
                    ]);
                  }}
                >
                  add bellow
                </button>

                {ind == 0 ? null : (
                  <button
                    className="btn-default remove"
                    onClick={(e) => {
                      e.preventDefault();
                      const copy = chains;
                      copy.splice(ind, 1);
                      set_chains([...copy]);
                    }}
                    type="submit"
                  >
                    remove
                  </button>
                )}
              </div>
            </div>
          ))}
          {/* <div style={{ height: 24 }} /> */}
          <RangeInputForm
            value={temperature}
            setValue={set_temperature}
            title={"Temperature"}
            min={0}
            max={1}
            step={0.01}
            onChange={() => {}}
          />

          <RangeInputForm
            value={top_p}
            setValue={set_top_p}
            title={"Top P"}
            min={0}
            max={1}
            step={0.01}
            onChange={() => {}}
          />
          <RangeInputForm
            value={top_k}
            setValue={set_top_k}
            title={"Top K"}
            min={0}
            max={100}
            step={1}
            onChange={() => {}}
          />
          <RangeInputForm
            value={max_new_tokens}
            setValue={set_max_new_tokens}
            title={"Max New Tokens"}
            min={16}
            max={4096}
            step={2}
            onChange={() => {}}
          />
          <RangeInputForm
            value={chat_prompt_size}
            setValue={set_chat_prompt_size}
            title={"Chat Prompt Size"}
            min={0}
            max={200000}
            step={4}
            onChange={() => {}}
          />
          <RangeInputForm
            value={repetition_penalty}
            setValue={set_repetition_penalty}
            title={"Repetition Penalty"}
            min={0}
            max={2}
            step={0.01}
            onChange={() => {}}
          />
          <TextInputForm
            items={[
              {
                value: username,
                onChange: (event) => {
                  set_username(event.target.value);
                },
                placeholder: "child name",
                title: "CHILDNAME:  ",
              },
              {
                value: ainame,
                onChange: (event) => {
                  set_ainame(event.target.value);
                },
                placeholder: "ai name",
                title: "AINAME:  ",
              },
            ]}
          />
        </Form>
      </div>
    </div>
  );
}

export default ChainOfThoughtPrompt;
