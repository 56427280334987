import React from "react";
import LLama32ChatComponent from "./LLamaTextComponent";

export default function LLama32Chat() {
  return (
    <div className="App">
      <LLama32ChatComponent />
    </div>
  );
}
